import { Box, Flex, Link, Text } from '@chakra-ui/react'
import {
  RestrictedFooter,
  RestrictedFooterProps,
  RestrictedGovtMasthead,
} from '@opengovsg/design-system-react'
import { browserEnv } from '~/browserEnv'
import Image from 'next/image'
import {
  BackgroundBox,
  BaseGridLayout,
  LoginGridArea,
  SidebarGridArea,
  SgidLoginButton,
} from '~/features/sign-in/components'
import { withSessionSsr } from '~/lib/withSession'
import logo from '~/assets/armoury-logo-dark.svg'
import logoLight from '~/assets/armoury-logo-light.svg'
import ogpLogo from '~/assets/ogp-logo-alt.svg'
import signIn from '~/assets/sign-in.svg'
import { StaticRoutes } from '~/utils/RouterNavigation'
import { getRedirectPath } from '~/utils/url'

const title = browserEnv.NEXT_PUBLIC_APP_NAME

const footerLinks = [
  { label: 'Contact us', href: 'mailto:armoury@open.gov.sg' },
  { label: 'Guide', href: 'https://go.gov.sg/armoury-guide' },
  {
    label: 'Report Vulnerability',
    href: 'https://go.gov.sg/reportvulnerability',
  },
]

const Footer = (props?: Omit<RestrictedFooterProps, 'appName' | 'appLink'>) => (
  <RestrictedFooter
    appName={title.substring(0, 1).toUpperCase() + title.substring(1)}
    appLink=""
    footerLinks={footerLinks}
    {...props}
  />
)

const SignIn = () => {
  return (
    <BackgroundBox>
      <RestrictedGovtMasthead />
      <BaseGridLayout flex={1}>
        <SidebarGridArea>
          <Flex flexDir="column" h="100%">
            <Flex alignItems="center" justifyContent="center" flexGrow="1">
              <Image src={signIn} alt="Sign-In Image" aria-hidden priority />
            </Flex>
            <Flex flexDir="row" justifyContent="center">
              <Image src={logo} alt="Armoury Logo" aria-hidden />
              <Box
                w="1px"
                color="standard.white"
                mx="2rem"
                border="1px solid white"
              ></Box>
              <Image src={ogpLogo} alt="OGP Logo" aria-hidden />
            </Flex>
          </Flex>
        </SidebarGridArea>
        <LoginGridArea>
          <Box minH={{ base: 'auto', lg: '17.25rem' }} w="100%" h="100%">
            <Flex flexDir="column" h="100%">
              <Flex display={{ lg: 'none', base: 'block' }} mb="1.5rem">
                <Image
                  width={180}
                  src={logoLight}
                  alt="Armoury Logo"
                  aria-hidden
                />
              </Flex>
              <Flex
                flexDir="column"
                flexGrow="1"
                justifyContent="center"
                align={{ base: 'center', lg: 'flex-start' }}
              >
                <Box mb="2.5rem">
                  <Text
                    mt={{ base: 0, sm: '1rem' }}
                    textStyle={{ lg: 'h2', base: 'h3-semibold' }}
                    textAlign={{ base: 'center', lg: 'left' }}
                    color="grey.900"
                  >
                    Simplifying compliance <br />
                    checks for government
                  </Text>
                </Box>
                <SgidLoginButton />
                <Flex
                  display={{ lg: 'none', base: 'flex' }}
                  mt="2.5rem"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image
                    width={320}
                    src={signIn}
                    alt="Sign-In Image"
                    aria-hidden
                    priority
                  />
                </Flex>
              </Flex>

              <Flex pt="3rem" display={{ lg: 'flex', base: 'none' }}>
                {footerLinks.map((link, index) => (
                  <Flex key={index} mr="2rem">
                    <Link
                      href={link.href}
                      textDecoration="none"
                      sx={{
                        all: 'unset',
                        _hover: {
                          cursor: 'pointer',
                          textDecoration: 'none',
                        },
                      }}
                    >
                      {link.label}
                    </Link>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Box>
        </LoginGridArea>
      </BaseGridLayout>
      <Box display={{ lg: 'none', base: 'block' }} id="footer">
        <Footer />
      </Box>
    </BackgroundBox>
  )
}

export const getServerSideProps = withSessionSsr(
  async function getServerSideProps({ req, query }) {
    const { callbackUrl } = query
    const user = req.session.user

    if (user) {
      return {
        redirect: {
          destination: getRedirectPath(
            StaticRoutes.LISTS_DASHBOARD,
            callbackUrl as string,
          ),
        },
        props: {},
      }
    }

    return {
      props: {},
    }
  },
)

export default SignIn
